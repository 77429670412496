import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import moment from 'moment';

import { DatetimeModalComponent } from '../datetime-modal/datetime-modal.component';
import { TimePickerComponent } from '../time-picker/time-picker.component';

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm';

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
})
export class DatetimePickerComponent implements OnInit {
  @Input() initialDate?: string;
  @Input() initialTime?: string;
  @Input() datePickerTitle = 'Select a date';
  @Input() timePickerTitle = 'Select a time';
  @Input() disabled: boolean = false;
  @Input() toDate?: string;

  @Output() datetimeChanged = new EventEmitter<[string, string]>();

  date: string;
  time?: string;

  constructor(
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
  ) {}

  ngOnInit() {
    const [date, time] = this.roundDatetime(this.initialDate ?? moment().format(DATE_FORMAT), this.initialTime);

    this.date = date;
    this.time = time;

    this.datetimeChanged.next([this.date, this.time]);
  }

  async editDate() {
    const datePickerModal = await this.modalCtrl.create({
      component: DatetimeModalComponent,
      cssClass: 'datetime-modal',
      componentProps: {
        presentation: 'date',
        min: moment().toDate(),
        max: this.toDate ? new Date(this.toDate) : undefined,
        selected: this.toDate ? new Date(this.toDate) : undefined,
        title: this.datePickerTitle,
      },
    });

    await datePickerModal.present();

    datePickerModal.onDidDismiss().then((data) => {
      this.date = moment(data.data.value).format(DATE_FORMAT);

      this.datetimeChanged.next([this.date, this.time]);
    });
  }

  async editTime() {
    // TODO: not create a popover every time you click
    // These are not getting removed from memory properly
    const popover = await this.popoverCtrl.create({
      component: TimePickerComponent,
      componentProps: {
        callback: (time: string) => {
          this.time = moment(`${this.date} ${time}`, `${DATE_FORMAT} LT`).format(TIME_FORMAT);

          popover.dismiss();

          this.datetimeChanged.next([this.date, this.time]);
        },
      },
      showBackdrop: false,
      event,
    });

    popover.present();
  }

  getDatetime() {
    return moment(`${this.date} ${this.time}`).toDate();
  }

  roundDatetime(date: string, time?: string) {
    if (!time) {
      return [date, undefined];
    }

    const [_hours, minutes] = time.split(':');
    const minutesNum = Number(minutes);
    const roundedMinutes = Math.round(minutesNum / 15) * 15;

    return moment(`${date} ${time}`)
      .add(roundedMinutes - minutesNum, 'minutes')
      .format(`${DATE_FORMAT} ${TIME_FORMAT}`)
      .split(' ');
  }
}
