<div>
  <ion-button color="light" (click)="editDate()" class="date-button" [disabled]="disabled">
    <ion-icon name="calendar" class="padded-icon"></ion-icon>
    {{ date | date: 'MMMM d' }}
  </ion-button>
  <ion-button
    (click)="editTime()"
    color="light"
    class="date-button"
    [ngClass]="{ 'pill-button-active': !time }"
    [disabled]="disabled"
  >
    <ion-icon name="time-outline" class="padded-icon"></ion-icon>
    {{ time ? (getDatetime() | date: 'h:mm a') : timePickerTitle }}
  </ion-button>
</div>
